<template>
  <div class="bg-black-0 rounded p-3 shadow-sm">
    <h2>Dropping</h2>

    <b-row class="mt-3 align-items-end">
      <b-col>
        <p>semua ({{ table.total }})</p>
        <b-button variant="primary-2" to="/lab/dropping/add"
          >Tambah data</b-button
        >
      </b-col>
      <b-col>
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="table.search"
            type="search"
            placeholder="Search"
            class="border-left-0"
            @input="debounceSearch"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <b-table
      class="mt-3"
      :items="table.items"
      :fields="table.fields"
      :busy="table.isBusy"
      small
      responsive
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(action)="row">
        <div class="d-flex">
          <b-button variant="warning" :to="`/lab/dropping/file/${row.item.id}`">
            <i class="ri-printer-line mr-1" />
            Cetak</b-button
          >
        </div>
      </template>
    </b-table>

    <b-pagination
      align="right"
      v-model="table.currentPage"
      :total-rows="table.total"
      :per-page="table.perPage"
      @change="changePage()"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import manageDroppingAPI from "../../../../api/dropping/manageDroppingAPI";
import debounce from "lodash/debounce";

export default {
  name: "BloodbagDropping",
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      table: {
        items: [],
        fields: [
          {
            key: "no_dropping",
            label: "Nomor Pengiriman",
          },
          {
            key: "hospital",
            label: "Nama Rumah Sakit",
          },
          {
            key: "date",
            label: "Tanggal Pengiriman",
          },
          {
            key: "officer_name",
            label: "Nama Petugas",
          },
          {
            key: "action",
            label: "Aksi",
          },
        ],
        total: 0,
        currentPage: 1,
        perPage: 10,
        isBusy: false,
        search: "",
      },
    };
  },
  methods: {
    async fetchAllData() {
      this.table.isBusy = true;
      await manageDroppingAPI
        .getAll({
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
        })
        .then((response) => {
          this.table.isBusy = false;
          this.table.items = response.data.data.data;
          this.table.total = response.data.data.total;
          this.table.perPage = response.data.data.per_page;
          this.table.currentPage = response.data.data.current_page;
        });
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchAllData();
    },
    debounceSearch: debounce(function () {
      this.table.currentPage = 1;
      this.fetchAllData();
    }, 500),
  },
  mounted() {
    this.fetchAllData();
  },
};
</script>
